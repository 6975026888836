<template>
	<v-chip pill class="v-chip--active" label outlined :color="datos.color" v-bind="$attrs" v-on="$listeners">
		<v-icon v-if="!noIcon" :small="'small' in $attrs" :x-small="'x-small' in $attrs" left>{{ datos.icon }}</v-icon>
		<span class="font-weight-bold text-caption">{{ value }}</span>
		<slot/>
	</v-chip>
</template>

<script>
import { getSituacionIcon } from "@/utils/index.js";

export default {
	props: { value: String, noIcon: {
		type: Boolean,
		default: false
	} },
	computed: {
		datos() {
			return getSituacionIcon(this.value) || {};
		},
	},
};
</script>

<style>

</style>